.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  margin:0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes dashBack {
  to {
    stroke-dashoffset: 10000;
  }
}
span.diag {
  display: block;
  line-height: 14px;
}
.detailPanel {
  height: 0px;
  width:100%;
  background-color: #3e3c45;
  position:absolute;
  bottom:0;
  transition: height 1s;
  color: #666;
}

.detailPanel.show {
  height: 610px;
}

.flex-direction {
  display: column;
}

.detailPanel .detail-chart {
  float:left;
}

.stratSelect {
  float:left;
  
}

.timeSelect {
  float:right;
  position:relative;
  margin-right: 630px;
}

@media (max-width : 1500px) {
  .timeSelect {
    margin-right: 0px;
  }
}

@media (max-width : 1185px) {
  .detailPanel .detail-chart {
    float:unset;
    width:97% !important;
  }

  .detailPanel.show {
    height: 100%;
  }

  .stratSelect {
    float:unset;

  }
}


.detailPanel .widget {
  width: 500px;
  height: 50px;
  border: 1px solid black;
  color: #FFFFFF;
  overflow: hidden; 
  display: column;
}


.node-props-button {
  position: absolute;
  right:0px;
  top:0px;
}
.icon-button {
  border: 1px solid #666;
  color: #666;
  padding: 8px;
  border-radius: 3px;
  font-size: 14px;
  background-color: rgba(0,0,0,0.1);
  cursor: pointer;
}
.icon-button.toprcorner{ 
  margin: 10px 10px 0 0;
}

.node-props {
  font-size: 13px;
  position: absolute;
  background-color: white;
  padding: 0.5rem 1rem;
  width:200px;
  right:0px;
  color:black;
  text-align:left;
}

.node-props label {
  margin: 0 15px 0 0;
}

.node-props select {
 
  color:black;
}
.node-props option.heading {
  font-weight: bold;
}

.node-props>div {
  margin-bottom: 8px;
}

.prop {
  max-width: 200px;
}
.Overview {
  width: 100%;
  bottom: 0;
  position: absolute;
  background-color: #3e3c45;
  overflow-y: hidden;
  height: 55px;
}
.Overview.showexpanded {
  height: 245px;
}
.Overview label {
  margin-right: 15px;
  font-size: 18px;
}
.flex-grid {
  display: flex;
}
.col {
  flex: 150;
}
span.nodeTitle {
  color: aliceblue;
  font-size: 14px;
  margin-right: 10px;
  display: block;
  margin-top: 3px;
  margin-bottom: 0;
  line-height: 16px;
}
.boxtext {
  display: block;
}
.boxtext.right {
  transform: skewX(60deg) skewY(343.8deg) scale(1.9,0.5);
  /* position: absolute; */
  top: -50px;
  left: 115px;
}
.boxtext.right span,.boxtext.left span {
  text-align: right;
}
.boxtext.left {
  transform: skewX(-59deg) skewY(16deg) scale(1.5,0.6);
  position: absolute;
  top: -28px;
  left: -30px;
}
.boxtext.rightVert {
  transform: skewY(331deg);
  width:120px;
  left: 30px;
  position: absolute;
  text-align: left;
  top: -140px;
}
.boxtext.leftVert {
  transform: skewY(26deg);
  width:120px;
  left: -120px;
  position: absolute;
  text-align: right;
  top: -140px;
}
span.diag span.head {
  font-size: 14px;
  color: #aaa;
  margin-right: 10px;
}
span.val {
  font-size: 20px !important;
  color: #aaa;
}
span.units {
  font-size: 10px !important;
  color: #b2b2b2;
  margin-right: 10px;
}
.Overview {
  font-size: 14px;
}
.Overview p {
  font-size: 13px;
}
.Overview h1 {
  font-size: 18px;
}
.diag.Demand span.val {
  color: red;
}
.diag.Generation span.val {
  color: #49ff49;
}
.diag span.units {
  color: white;
  margin-left: 6px;
}
/* svg.energy-flow {
  position: absolute;
  animation: 400s linear 0s infinite normal forwards dash;
  stroke-dashoffset: 10000;
} */
svg.energy-flow.import {
  position: absolute;
  animation: 400s linear 0s infinite normal backwards dashBack;
  stroke-dashoffset: 0;
}

svg.energy-flow.export {
  position: absolute;
  animation: 400s linear 0s infinite normal forwards dash;
  stroke-dashoffset: 10000;
}

.datePicker {
  border: 1px solid #666;
  color: white;
  background-color: rgba(0,0,0,0.1);
  border-radius: 3px;
  padding: 8px;
  font-size: 14px;
}